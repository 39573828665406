<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md4 lg4 xl4">
      <va-card-content>
        <monta-form :form="formAddBot" @complete="(data)=>submitFormNew(data, this)" />
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {submitFormNew} from './AddBotFunctions'
import FormStruct from "./AddBotFormStruct";
import {loadForms, loadListas} from "./AddBotFunctions";
export default {
  name: "AddBots",
  async created() {
    await loadListas(this);
    loadForms(this);
  },
  data() {
    return {
      formAddBot: {
        struct: FormStruct
      },
      listas: [],
      loaded: false,
      prevRoute: null,
      userBotOwner: '',
    }
  },
  methods: {
    submitFormNew,
    checkId(){
      //console.log('checkId')
      //console.log(this.prevRoute.params.id)
      this.userBotOwner = this.prevRoute.params.id
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted() {
    if(!this.prevRoute){
      this.$router.push({name: 'painel-afiliado-users-listagem'});
    } else {
      this.checkId()
    }
  }
}
</script>

<style scoped>

</style>