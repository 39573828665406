import {FetchWs} from "@/functions/FetchWs";
import FormStruct from "./UserEditFormStruct";
import {AuthConstants} from "@/constants/Auth";

export async function getUser(id: number): Promise<any>
{
    const response = await FetchWs('user/get-user/' +id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

export async function carregaDadosParaFormUpdate(vue: any)
{
    const user = await getUser(vue.idUser)
    const struct = FormStruct;
    struct[0][0].value = user[0]['usu_name']
    struct[1][0].value = user[0]['usu_email']
    vue.form.struct = struct
    vue.formCarregado++
}

export async function submitFormUpdate(data: any, vue: any)
{
    const body = new FormData();
    body.append("usuario", data.usu_name);
    body.append("email", data.usu_email);
    body.append("grupo", AuthConstants.GRUPO_CAMPANHAS_ID.toString());
    const response = await FetchWs('user/update/' + vue.idUser, 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}