
export function setOptionsForm(vue: any, form: string, index: string, options: string[]){
    const tmpLine = vue[form].struct[getTmpLineKey(vue, form, index)];
    vue[form].struct[getTmpLineKey(vue, form, index)][tmpLine.findIndex((obj: any) => obj.name == index)].options = options;
}

export function setDadosForm(vue: any, form: string, index: string, value: string){
    const tmpLine = vue[form].struct[getTmpLineKey(vue, form, index)];
    vue[form].struct[getTmpLineKey(vue, form, index)][tmpLine.findIndex((obj: any) => obj.name == index)].value = value;
}

export function getTmpLineKey(vue: any, form: string, index: string){
    return vue[form].struct.findIndex((line: any) => line.findIndex((obj: any) => obj.name == index) !== -1);
}