import {FetchWs} from "@/functions/FetchWs";
import {setOptionsForm} from "@/components/monta-form/functions";

export async function submitFormNew(data: any, vue: any)
{
    const lista = vue.listas.find((lista: any) => lista.grp_nome == data.usu_cargo)
    const body = new FormData();
    body.append("usuario", data.usu_name);
    body.append("email", data.usu_email);
    body.append("senha", data.usu_pass);
    body.append("cargo", lista.grp_id);
    const response = await FetchWs('user/register', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'painel-admin-users-listagem'});
    }
}

export function loadForms(vue: any){
    setOptionsForm(vue, 'formNewUser', 'usu_cargo', vue.listas.map((v:any) => v.grp_nome))
    vue.loaded = true
}

export async function loadListas(vue: any) {
    const response = await FetchWs('user/get-group-perms');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.listas = data;
    }
}