<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg8 xl8">
      <va-card-content>
        <monta-form v-if="loaded" :form="formNewCampaign" @complete="(data) => submitFormCampaign(this,data)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import FormNewCampaignStruct from "./newCampaignFormStruct";
import {loadForms, loadListas, submitFormCampaign} from "./functions";

export default {
  name: "NewCampaign",
  async created() {
    await loadListas(this);
    loadForms(this);

  },
  data() {
    return {
      loaded: false,
      listas: [],
      formNewCampaign: FormNewCampaignStruct
    }
  },
  methods: {
    submitFormCampaign
  }
}
</script>

<style scoped>

</style>