import {FetchWs} from "@/functions/FetchWs";
import {setOptionsForm} from "@/components/monta-form/functions"

export async function loadListas(vue: any) {
    const response = await FetchWs('list/get-all');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.listas = data;
    }
}

export function loadForms(vue: any){
    setOptionsForm(vue, 'formNewCampaign', 'campanha_lista', vue.listas.map((v:any) => v.name))
    vue.loaded = true
}

export async function submitFormCampaign(vue: any, data: any){
    const lista = vue.listas.find((lista: any) => lista.name == data.campanha_lista)
    const body = new FormData();
    body.append("name", data.campanha_nome);
    body.append("title", data.campanha_titulo);
    body.append("message", data.campanha_mensagem);
    body.append("btn_action_text", data.campanha_btn_acao);
    body.append("notification_destination", data.campanha_notificacao);
    body.append("notification_interval", data.intervalo_notificacao);
    body.append("initial_date", data.campanha_agendamento);
    body.append("initial_notification_hour", data.campanha_hora_inicio);
    body.append("final_notification_hour", data.campanha_hora_fim);
    body.append("thanks_message", data.campanha_mensagem_agradecimento);
    body.append("list", lista.id);
    const response = await FetchWs('campaign/new', 'POST', body);
    if(response?.success) {
        vue.$router.push({name: 'painel-campanhas'})
    }
}