import {FetchWs} from "@/functions/FetchWs";
import {AuthConstants} from "@/constants/Auth";

export async function submitFormNew(data: any, vue: any)
{
    const body = new FormData();
    body.append("usuario", data.usu_name);
    body.append("email", data.usu_email);
    body.append("senha", data.usu_pass);
    body.append("cargo", AuthConstants.GRUPO_CAMPANHAS_ID.toString());
    const response = await FetchWs('user/register', 'POST',body);
    if(response?.success) {
        await vue.$router.push({name: 'painel-afiliado-users-listagem'});
    }
}