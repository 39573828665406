<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md4 lg4 xl4">
      <va-card-content>
        <monta-form v-if="formCarregado" :key="formCarregado" :form="form" @complete="(data)=>submitFormUpdate(data, this)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {carregaDadosParaFormUpdate, submitFormUpdate} from "./EditUserFunctions"

export default {
  name: "EditUser",
  props:['id'],
  async mounted() {
    await this.carregaDadosParaFormUpdate(this)
  },
  data() {
    return {
      form: {},
      formCarregado: 0,
      idUser: this.$route.params.id
    }
  },
  methods:{
    carregaDadosParaFormUpdate,
    submitFormUpdate
  },
}
</script>

<style scoped>

</style>