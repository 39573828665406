import {isString} from "@/functions/Validations";

export default [
    [
        {
            col: 12,
            name: "usu_name",
            value: "",
            type: "text",
            placeholder: "Ex.: Seu nome",
            label: "Usuário",
            required: true,
            leftIcon: "person",
            validateFunc: (val: any) => {
                if(!isString(val)) {
                    return false;
                }
                val = val.trim();
                if(val.length <= 7){
                    return 'Nome inválido';
                }
                return true;
            }
        }
    ],
    [
        {
            col: 12,
            name: "usu_email",
            customClass: "teste",
            value: "",
            type: "email",
            placeholder: "Ex.: nome@exemplo.com",
            label: "E-mail",
            leftIcon: "mail_outline",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "usu_pass",
            value: "",
            type: "password",
            placeholder: "Ex.: **********",
            label: "Senha",
            leftIcon: "password",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "usu_cargo",
            value: "",
            type: "select",
            options: [],
            label: "Cargo",
            leftIcon: "badge",
            required: true
        }
    ]
]