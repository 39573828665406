import {FetchWs} from "@/functions/FetchWs";
import FormStruct from "./UserEditFormStruct";

export async function getUser(id: number): Promise<any>
{
    const response = await FetchWs('user/get-user/' +id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

export async function carregaDadosParaFormUpdate(vue: any)
{
    const user = await getUser(vue.idUser)
    const response = await FetchWs('user/get-group-perms');
    vue.Groups = response.data
    vue.Perms = vue.Groups.map((p:any) => p.grp_nome)

    const nomePerm = vue.Groups.find((e:any)=>e.grp_id===user[0].usu_grp_id)

    const struct = FormStruct;
    struct[0][0].value = user[0]['usu_name']
    struct[1][0].value = user[0]['usu_email']
    // @ts-ignore
    struct[2][0].options = vue.Perms
    struct[2][0].value = nomePerm.grp_nome
    vue.form.struct = struct
    vue.formCarregado++
}

export async function submitFormUpdate(data: any, vue: any)
{
    const idPerm = vue.Groups.find((e:any)=>e.grp_nome === data.usu_grp_id)

    const body = new FormData();
    body.append("usuario", data.usu_name);
    body.append("email", data.usu_email);
    body.append("grupo", idPerm.grp_id);
    const response = await FetchWs('user/update/' + vue.idUser, 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}