<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md4 lg4 xl4">
      <va-card-content>
        <monta-form :form="formNewUser" @complete="(data)=>submitFormNew(data, this)" />
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import FormStruct from "./UserAddFormStruct"
import {submitFormNew, loadForms, loadListas} from "./AddUserFunctions"

export default {
  name: "AddUser",
  async created() {
    await loadListas(this);
    loadForms(this);
  },
  data() {
    return {
      formNewUser: {
        struct: FormStruct
      },
      listas: [],
      loaded: false,
    }
  },
  methods:{
    submitFormNew
  }
}
</script>

<style scoped>

</style>