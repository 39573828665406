export default [
    [
        {
            col: 12,
            name: "bot_name",
            value: "",
            type: "text",
            placeholder: "Ex.: Alperbot",
            label: "Nome do Bot",
            required: true,
            leftIcon: "smart_toy"
        }
    ],
    [
        {
            col: 12,
            name: "plano_lista",
            value: "",
            type: "select",
            options: [],
            placeholder: "Ex: WhatsApp Bot Receptivo",
            label: "Lista de planos",
            required: true
        },
    ]
]