import {FetchWs} from "@/functions/FetchWs";
import {setOptionsForm} from "@/components/monta-form/functions";

export async function submitFormNew(data: any, vue: any)
{
    const lista = vue.listas.find((lista: any) => lista.name_plan == data.plano_lista)
    const body = new FormData();
    body.append("name", data.bot_name);
    body.append("userBotOwner", vue.userBotOwner);
    body.append("pla_id", lista.id);
    const response = await FetchWs('bot/new', 'POST',body);
    if(response?.success) {
        vue.$router.back()
    }
}

export function loadForms(vue: any){
    setOptionsForm(vue, 'formAddBot', 'plano_lista', vue.listas.map((v:any) => v.name_plan))
    vue.loaded = true
}

export async function loadListas(vue: any) {
    const response = await FetchWs('plan/get-all');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.listas = data;
    }
}